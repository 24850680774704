<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('delete') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-delete-solid"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleDelete"
            v-if="
              multipleSelectionPres.length == 1 &&
                multipleSelectionPres[0].status == '已预约'
            "
            >删除班次
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-s-operation"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleEdit"
            v-if="
              multipleSelectionPres.length ==1 &&
                multipleSelectionPres[0].status == '已预约'
            "
            >修改班次
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
      </div>
    </div>
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="handleCustomerDetail(scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'imageBtn' && column.visible === true
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row[column.column_prop].length > 0"
              style="font-size: 12px; color: #2379fb"
              @click="
                handleShowImage(
                  scope.row[column.column_prop],
                  scope.row[column.column_prop][0]
                )
              "
            >
              <a>点击查看图片</a>
              <el-image
                style="width: 0px; height: 0px"
                :ref="scope.row[column.column_prop][0]"
                :src="imageUrl"
                :preview-src-list="scope.row[column.column_prop]"
                :z-index="9999"
              >
              </el-image>
            </div>
            <div v-else>
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>

              <el-input
                v-if="'doc_name' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.doctor_name"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.doc_name)
                "
              >
              </el-input>

              <el-input
                v-if="'pat_name' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.pat_name"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.pat_name)
                "
              >
              </el-input>
              <el-select
                v-else-if="'status' == column.column_prop"
                style="width:200px"
                v-model="searchParams.status"
                placeholder="请选择"
                size="mini"
                clearable
                @change="handleSearch(column.column_prop, searchParams.status)"
              >
                <template slot="prefix">
                  <i
                    class="el-icon-search"
                    style="width: 25px; line-height: 32px;"
                  ></i>
                </template>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-else-if="'book_time' == column.column_prop"
                type="date"
                size="mini"
                v-model="choiceDateCreateRecord"
                placeholder="选择日期"
                @change="handleCreateRecordChangeDate"
              >
              </el-date-picker>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--编辑表头-->
    <makeAppointmentTableHead
      :isActiveMakeAppointmentTableHead.sync="isActiveMakeAppointmentTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveMakeAppointmentTableHead"
      @getList="getList"
    >
    </makeAppointmentTableHead>
    <el-dialog
      :visible.sync="diaFlg"
      width="30%"
      :before-close="handleClose"
    >
      <span slot="title" style="font-size:14px;">修改预约时间</span>
      <makeAppointmentInfo ref="makeAppointmentInfo" :dateTimeList="dateTimeList" :doctor_id="multipleSelectionPres.length > 0 ? multipleSelectionPres[0].doctor_id : 0" :id="multipleSelectionPres.length > 0 ? multipleSelectionPres[0].id : 0" @customEvent="handleCustomEvent"></makeAppointmentInfo>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="handleClose">取 消</el-button>
        <el-button size="mini" type="primary" @click="_ok"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import makeAppointmentTableHead from "@/views/workManagement/components/makeAppointmentTableHead";
import makeAppointmentInfo from "@/views/workManagement/components/makeAppointmentInfo";
import {
  getBookingList,
  getMyBookingDate,
  putMyBookingTime,
  deleteCancelBooking,
} from "@/api/rowWorks/index";
import { mapState } from "vuex";
export default {
  name: "makeAppointment",
  components: {
    makeAppointmentTableHead,
    Pagination,
    makeAppointmentInfo
  },
  async created() {
    this.getList();
    await this._getSchedusList();
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  computed:{
    ...mapState({
      table_options: (state) => state.user.table_options,
    })
  },
  data() {
    return {
      operateList: [],
      diaFlg: false,
      options: [
        {
          value: 1,
          label: "已预约",
        },
        {
          value: 2,
          label: "已接诊",
        },
        {
          value: 3,
          label: "已取消",
        },
      ],
      searchTableHead: [
        {
          name: "book_time",
          isSearch: false,
        },
        {
          name: "doc_name",
          isSearch: false,
        },
        {
          name: "pat_name",
          isSearch: false,
        },
        {
          name: "status",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      searchParams: {
        keywords: "",
      },
      isActiveMakeAppointmentTableHead: false,
      tableHead: [],
      choiceDateCreateRecord: "",
      total: 0,
      listLoading: false,
      tableData: [],
      info: {},
      dateTimeList:[],//获取最近十五天日期
      multipleSelection: [],
      multipleSelectionPres: [],
      postTime:{},//组件拿过来的值
    };
  },
  methods: {
    _ok(){
      let data = this.postTime
      putMyBookingTime(data).then(res=>{
        if(res.code == 200){
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.getList();
          this.diaFlg = false;
        }else{
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    handleCustomEvent(e){
      console.log(e,'emit')
      this.postTime = e
    },
   async _getMyBookingDate(data){
    const res = await getMyBookingDate(data)
    if(res.code == 200){
      console.log(res)
      this.dateTimeList = res.data
    }else{
      this.$message({
        type: "error",
        message: res.msg,
      });
    }
   },
    handleClose() {
      console.log("点击");
      this.diaFlg = false;
      console.log(this.$refs.makeAppointmentInfo,'子组件');
      this.postTime = {}
      this.$refs.makeAppointmentInfo.info = {};
    },
    //编辑
   async handleEdit() {
      console.log("编辑");
      let data= {
        doctor_id:this.multipleSelectionPres[0].doctor_id
      }
      await this._getMyBookingDate(data)
      this.diaFlg = true;
    },
    //删除预约
    handleDelete() {
      //提示框
      this.$confirm("此操作将永久删除该预约, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            const res = await deleteCancelBooking(
              this.multipleSelectionPres[0].id
            );
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          } catch (err) {
            //在此处理错误
            console.log(err);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async _getSchedusList() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "makeAppointment",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.makeAppointment;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    getList(type) {
      console.log("getList触发");
      if (type == "restPage") {
        this.listQuery.page = 1;
      }
      console.log("getList触发");
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.limit = this.listQuery.limit;
      getBookingList(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    handleSearch(prop, value) {
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {};
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = "";
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveMakeAppointmentTableHead = true;
    },

    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
      console.log(this.multipleSelectionPres);
    },
    handleCreateRecordChangeDate() {
      let year = this.choiceDateCreateRecord.getFullYear();
      let month = this.choiceDateCreateRecord.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let day = this.choiceDateCreateRecord.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let date = year + "-" + month + "-" + day;
      this.searchParams.book_at = date;
      this.getList("restPage");
    },
  },
};
</script>

<style lang="scss" scoped>
.isSearch {
  color: #f56c6c;
}

.search-icon {
  color: #f56c6c;
}

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}

/*抽屉高度*/
::v-deep .el-drawer {
  bottom: 0 !important;
}

::v-deep .el-drawer__header {
  padding: 0;
  margin: 0;
}

::v-deep .el-drawer.rtl {
  overflow: visible;
  height: 100vh;
  bottom: 0;
  margin-top: 0vh;
  position: absolute;
}
</style>
